import Link from "next/link"
import { Button } from "@/components/ui/button"

export default function NotFound() {
  return (
    <div className="text-center space-y-8">
      <p className="stroked mr-7 md:leading-[200px] w-auto py-4 text-6xl font-black md:py-8 md:text-[6rem] transition-all duration-300 ease-linear">
        Oh sh!t you found nothing!
      </p>
      <Button size="lg" variant="default" asChild>
        <Link href="/">
          Go back home
        </Link>
      </Button>
    </div>
  )
}